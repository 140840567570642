import React, { useState, useEffect, useRef } from "react";
import Layout from "../../components/Layout/Layout";
import styled from "styled-components";
import gsap from "gsap";
import szymonMowiLogo from "../../img/szymonMowiLogo.svg";
import wojnaIdeiLogo from "../../img/wojnaIdeiLogo.svg";
import { mediaQuery } from "../../components/_breakpoints";
import { graphql, Link } from "gatsby";

const QuestionComponent = ({ question, answer, isChangedSection }) => {
  const [isOpen, setIsOpen] = useState(false);
  const answerRef = useRef(null);

  useEffect(() => {
    if (answerRef.current !== null && isOpen) {
      gsap.to(answerRef.current, {
        height: "auto",
        visibility: "visible",
        duration: 0.4,
        opacity: 1,
      });
    }
    if (answerRef.current !== null && !isOpen) {
      gsap.to(answerRef.current, {
        height: 0,
        visibility: "hidden",
        duration: 0.4,
        opacity: 0,
      });
    }
  }, [isOpen]);

  useEffect(() => {
    return () => {
      setIsOpen(false);
      gsap.to(answerRef.current, {
        height: 0,
        visibility: "hidden",
        duration: 0.4,
        opacity: 0,
      });
    };
  }, [isChangedSection]);
  const pattern = /\[(.*?)\]/;
  const splitedAnswer = answer.split(pattern);
  return (
    <QuestionWrapper>
      <Button>
        <Box onClick={() => setIsOpen((prevState) => !prevState)}>
          <Switch>{isOpen ? "—" : "+"}</Switch>
          <Question>&nbsp;{question}</Question>
        </Box>
      </Button>
      <Answer ref={answerRef}>
        {splitedAnswer.length > 0
          ? splitedAnswer.map((el) =>
              el.indexOf("==") > 0 ? (
                <LinkStyled href={el.split("==")[1]}>
                  {el.split("==")[0]}
                </LinkStyled>
              ) : (
                el
              )
            )
          : answer}
      </Answer>
    </QuestionWrapper>
  );
};

export default ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const data = [...edges.map((el) => el.node.frontmatter)];
  const dataSzymonMowi = data.find((obj) => obj.channelName === "szymon-mowi");
  const dataWojnaIdei = data.find((obj) => obj.channelName === "wojna-idei");
  const [isFirstLogo, setIsFirstLogo] = useState(true);

  return (
    <Layout>
      <Section>
        <TitleSectionWrapper>
          <SectionTitle>{dataSzymonMowi.title}</SectionTitle>
          <SwitcherWrapper>
            <SwitcherTitle>Wybierz kanał: </SwitcherTitle>
            <FirstLogo
              onClick={() => setIsFirstLogo(true)}
              src={wojnaIdeiLogo}
              isFirstLogo={isFirstLogo}
            />
            <SecondLogo
              onClick={() => setIsFirstLogo(false)}
              src={szymonMowiLogo}
              isFirstLogo={isFirstLogo}
            />
          </SwitcherWrapper>
        </TitleSectionWrapper>
        <QuestionsWrapper>
          {isFirstLogo
            ? dataWojnaIdei.questions.map(({ question, answer }) => (
                <QuestionComponent
                  question={question}
                  answer={answer}
                  isChangedSection={isFirstLogo}
                />
              ))
            : dataSzymonMowi.questions.map(({ question, answer }) => (
                <QuestionComponent
                  question={question}
                  answer={answer}
                  isChangedSection={isFirstLogo}
                />
              ))}
        </QuestionsWrapper>
      </Section>
    </Layout>
  );
};

export const faqPageQuery = graphql`
  query FaqPageTemplate {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "faq-page" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            questions {
              answer
              question
            }
            channelName
          }
        }
      }
    }
  }
`;

const Text = styled.div`
  display: flex;
`;

const LinkStyled = styled.a.attrs({ target: "_blank" })`
  display: inline-block;
`;

const TitleSectionWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  font-size: 18px;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 60px;
  z-index: 50;
  height: 100%;
  padding: 0 20px;
  width: 100%;
  ${mediaQuery.sm(`
    align-items: flex-start;
    margin-left: 100px;
    margin-top: 100px;
  `)}
`;

const SectionTitle = styled.h3`
  display: flex;
`;

const QuestionsWrapper = styled.ul`
  list-style: none;
  background: rgba(255, 255, 255, 0.5);
  padding: 5px;
  border-radius: 5px;
  margin: 0;
`;

const FirstLogo = styled.img`
  order: ${({ isFirstLogo }) => (isFirstLogo ? 2 : 3)};
  margin-left: ${({ isFirstLogo }) => (isFirstLogo ? 17 : 10)}px;
  height: ${({ isFirstLogo }) => (isFirstLogo ? 34 : 19)}px;
  width: ${({ isFirstLogo }) => (isFirstLogo ? 35 : 19)}px;

  &:hover {
    cursor: pointer;
    transform: scale(${({ isFirstLogo }) => (isFirstLogo ? 1 : 1.5)});
  }
`;

const SecondLogo = styled.img`
  order: ${({ isFirstLogo }) => (isFirstLogo ? 3 : 2)};
  margin-left: ${({ isFirstLogo }) => (isFirstLogo ? 10 : 17)}px;
  height: ${({ isFirstLogo }) => (isFirstLogo ? 19 : 34)}px;
  width: ${({ isFirstLogo }) => (isFirstLogo ? 19 : 34)}px;

  &:hover {
    cursor: pointer;
    transform: scale(${({ isFirstLogo }) => (isFirstLogo ? 1.5 : 1)});
  }
`;

const QuestionWrapper = styled.li`
  margin: 0 0 11px;
`;

const Question = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  font-family: "Source Sans Pro", sans-serif;
  ${mediaQuery.sm(`
    margin: 3px 0;
    font-size: 24px;
    font-weight: bold;
  `)}
  ${mediaQuery.xl(`font-size: 24px; font-weight: 500;`)}
`;

const Button = styled.button`
  padding: 0;
  border: none;
  background: none;
  outline: none;

  &:hover,
  &:focus {
    cursor: pointer;
  }
`;

const Answer = styled.p`
  display: block;
  flex-direction: column;
  padding-left: 15px;
  margin: 4px 0 0 0;
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  height: 0;
  opacity: 0;
  visibility: hidden;

  ${mediaQuery.sm(`
    font-size: 18px;   
    line-height: 22px;
  `)}
`;

const Box = styled.div`
  display: flex;
  align-items: center;
`;

const Switch = styled.div`
  font-weight: 600;
  ${mediaQuery.sm(`
    font-size: 26px;
    font-weight: 600;
  `)}
`;

const SwitcherWrapper = styled.div`
  display: flex;
  position: relative;
  left: -10px;
  margin: 10px 0;
  align-items: center;
`;

const SwitcherTitle = styled.div`
  display: flex;
  margin-left: 20px;
`;
